import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head';
import layoutStyles from '../styles/style.module.scss'

const EducationPage = () => {
    return (
        <div>
            <Head title= "education"/>
            <Layout>
                <h1 className={layoutStyles.t1}>Education</h1>
                <nav>
                    <ul className={layoutStyles.indList}>
                        <li className={layoutStyles.t2}>
                            PhD in Physical Oceanography: Oceanographic Institute of the University of São Paulo - Brazil - 2014
                            <p className={layoutStyles.parag}>Dissertation: <a href="https://teses.usp.br/teses/disponiveis/21/21135/tde-11032015-141317/en.php" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Subinertial Oscillations in the South Brazil Bight: Numerical Studies (in Portuguese).</a></p>
                        </li>
                        <li className={layoutStyles.t2}>
                            M.S. in Physical Oceanography: Oceanographic Institute of the University of São Paulo - 2009
                            <p className={layoutStyles.parag}>Thesis: <a href="http://www.teses.usp.br/teses/disponiveis/21/21132/tde-19012010-165632/en.php" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Numerical Modeling of Sewage Plume for the Santos Outfall (in Portuguese).</a></p>
                        </li>
                        <li className={layoutStyles.t2}>
                            B.S. in Oceanography - Oceanographic Institute of the University of São Paulo - 2006
                            <p className={layoutStyles.parag}>BS Thesis: <a href="http://hpgregorio.net/arquivos/BSc_hpgregorio.pdf" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Study of Water Masses and Currents of Admiralty Bay, Antarctica, during the Summer (in Portuguese).</a></p>
                        </li>
                    </ul>
                </nav>
            </Layout>
        </div>
        
    )    
}

export default EducationPage